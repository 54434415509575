import ErpOrderList from "./list";
import ErpOrderShow from "./show";
import ErpOrderNew from "./new";


if (document.body.getAttribute("data-current-path") === "erp/orders") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new ErpOrderList();
    } else if(action === "show") {
        new ErpOrderShow();
    } else if(action === "new") {
        new ErpOrderNew();
    }
}